<template>
  <div id="search">
    <!-- FILTROS -->
    <b-row class="mb-2 ">
      <!-- Input de búsqueda -->
      <b-col sm="9">
        <b-form-input
          v-model="searchInput"
          :disabled="seleccionar"
          :placeholder="$t('BuscarContenidos')"
          autofocus
          autocomplete="off"
          @keyup="onKeyUp()"
        />
      </b-col>

      <!-- Botón de búsqueda -->
      <b-col>
        <b-button
          variant="info"
          :disabled="seleccionar"
          @click.stop.prevent="onKeyUp(true)"
        >
          <span style="vertical-align: text-top">{{ $t('Search') }}</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="5">
        <b-overlay
          variant="transparent"
          :show="searching"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <v-select
            id="catSelect"
            v-model="catSelc"
            :no-options-text="$t('categoryFilter')"
            :searchable="!searching"
            :search-input="searchName"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('categoryFilter')"
            :options="categoriesSearch"
            :disabled="!seleccionar && !orphan ? false : true"
            :open-on-focus="false"
            @search="delayMethod"
          />
        </b-overlay>
      </b-col>
      <b-col>
        <b-form-select
          v-model="selected"
          :disabled="!seleccionar ? false : true"
          :options="options"
        />
      </b-col>
      <b-col>
        <b-form-select
          v-model="orderBy"
          :disabled="!seleccionar ? false : true"
          :options="optionsOrder"
        />
      </b-col>
      <b-col class="align-items-center">
        <b-form-checkbox
          v-model="orphan"
          switch
          :disabled="!seleccionar && isEmpty(catSelc) ? false : true"
        >
          {{ $t('orphanContent') }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-2">
      <b-col md="3">
        <b-form-checkbox
          v-model="seleccionar"
          switch
        >
          {{ $t('activeSelectionContens') }}
        </b-form-checkbox>
      </b-col>

      <b-col md="2">
        <b-form-select
          v-if="seleccionar"
          v-model="state"
          :options="optionsStatus"
        />
      </b-col>
      <b-col
        v-if="seleccionar"
        md="2"
      >
        <b-button
          v-if="selectContent.length > 0 && state != null"
          variant="success"
          @click.stop.prevent="status()"
        >
          {{ $t('dataGeneric.change') }}
        </b-button>
        <b-button
          v-else
          disabled
        >
          {{ $t('dataGeneric.change') }}
        </b-button>
      </b-col>
      <b-col
        v-if="seleccionar"
        md="2"
      >
        <b-dropdown
          right
          variant="primary"
          class="mr-1"
          :text="$t('resources.tableHeader.actions')"
        >
          <b-dropdown-item
            :disabled="selectContent.length == 0 ? true : false"
            @click.stop.prevent="showModal(0)"
          >
            {{ $t('addToCategory') }}
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="selectContent.length == 0 ? true : false"
            @click.stop.prevent="moveToCat()"
          >
            {{ $t('contents.moveToCat') }}
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="isEmpty(catSelc) || selectContent.length == 0 ? true : false
            "
            @click.stop.prevent="showModal(1)"
          >
            {{ $t('moveFromCategory') }}
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="isEmpty(catSelc) || selectContent.length == 0 ? true : false
            "
            @click.stop.prevent="deleteFromCategory()"
          >
            {{ $t('removeFromCategory') }}
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="selectContent.length == 0 ? true : false"
            variant="danger"
            @click.stop.prevent="deleteContent()"
          >
            {{ $t('dataGeneric.delete') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox
          v-if="seleccionar"
          v-model="todos"
          switch
        >
          {{ $t('slider.allSelect') }}
        </b-form-checkbox>
      </b-col>
    </b-row>

    <!-- CONTENIDOS -->
    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="GridIcon" />
          <span>
            {{ $t('Cards') }}</span>
        </template>
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="selectContent"
          name="some-radios"
        >
          <b-overlay
            variant="transparent"
            :show="show"
            :opacity="1.00"
            :blur="'2px'"
            rounded="lg"
          >
            <b-row>
              <b-col>
                <span v-if="search && search.length != 0">{{ $t('resultFounds', { length: search.length }) }}</span>
                <span v-else>{{ $t('resultNotFound') }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-for="folder in search"
                :id="folder.node.id"
                :key="folder.node.id"
                class="mt-5"
                md="6"
                lg="4"
                xl="3"
                sm="12"
              >
                <b-card
                  :id="folder.node.id"
                  class="folder"
                  no-body
                  @click.stop.prevent="!seleccionar ? routeToContentView(folder.node.id) : clickCheck(folder.node)"
                >
                  <b-img-lazy
                    :src="buildImageUrl(folder)"
                    class="card-img-top cursor-pointer"
                    img-alt="Card image"
                    @error="errorImg"
                  />
                  <b-card-header>
                    <div id="acortarPadre">
                      <b-card-title
                        id="acortar"
                        class="mb-25"
                      >
                        <span
                          v-if="folder.node.state == 'GRE'"
                          class="mr-50 bullet bullet-success bullet-sm"
                        />
                        <span
                          v-if="folder.node.state == 'YEW'"
                          class="mr-50 bullet bullet-warning bullet-sm"
                        />
                        <span
                          v-if="folder.node.state == 'RED'"
                          class="mr-50 bullet bullet-danger bullet-sm"
                        />
                        <span
                          v-if="folder.node.state == 'BCK'"
                          class="mr-50 bullet bullet-dark bullet-sm"
                        />
                        {{ folder.node.name }}
                      </b-card-title>

                      <b-card-text
                        v-if="folder.node.isFinal != null"
                        class="mb-0"
                      >
                        {{ $t("contents.categories") }}
                      </b-card-text>
                      <b-card-text
                        v-else
                        class="mb-0"
                      >
                        {{ $t(typeOptions[folder.node.type]) }}
                      </b-card-text>
                    </div>
                  </b-card-header>
                  <b-card-body>
                    <div
                      v-if="!seleccionar"
                      class="actionsBar right"
                    >
                      <div class="action">
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template #button-content>
                            <feather-icon
                              color="white"
                              icon="MoreVerticalIcon"
                              size="20"
                            />
                          </template>

                          <b-dropdown-item @click.stop.prevent="selectItem(folder.node, 0)">
                            <feather-icon
                              icon="CornerRightUpIcon"
                              size="18"
                            />
                            {{ $t('addToCategory') }}
                          </b-dropdown-item>
                          <b-dropdown-item @click.stop.prevent="moveToCat(folder.node)">
                            <feather-icon
                              icon="CornerRightUpIcon"
                              size="18"
                            />
                            {{ $t('contents.moveToCat') }}
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="!isExternalLive(folder.node)"
                            @click.stop.prevent="deleteContent(folder.node.id)"
                          >
                            <feather-icon
                              icon="DeleteIcon"
                              size="18"
                            />
                            {{ $t("contents.dropContent") }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                    <div
                      v-else
                      class="actionsBar left"
                    >
                      <div class="action ml-1">
                        <b-form-checkbox
                          disabled
                          :value="folder.node.id"
                        />
                      </div>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-overlay>
        </b-form-checkbox-group>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ListIcon" />
          <span>{{ $t('List') }}</span>
        </template>
        <b-row class="mb-2">
          <b-col>
            <span v-if="search && search.length != 0">{{ $t('resultFounds', { length: search.length }) }}</span>
            <span v-else>{{ $t('resultNotFound') }}</span>
          </b-col>
        </b-row>
        <b-form-checkbox-group
          v-model="selectContent"
          name="some-radios"
        >
          <b-table-simple
            ref="refContentListTable"
            class="mt-2 mb-1"
            show-empty
            hover
            selectable
            :select-mode="single"
            :empty-text="$t('emptyText')"
            responsive
            primary-key="id"
            :no-border-collapse="true"
          >
            <b-thead>
              <b-tr>
                <b-th
                  v-for="i in tableColumns"
                  :key="i.key"
                >
                  {{
                    i.label
                  }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="item in search"
                :key="item.node.id"
                @click.stop.prevent="!seleccionar ? '' : clickCheck(item.node)"
              >
                <b-td @click.stop.prevent="!seleccionar ? onRowSelected(item) : ''">
                  <div
                    class="d-flex"
                    style="align-items: center"
                  >
                    <b-img-lazy
                      id="avatar"
                      class="mr-2"
                      rounded="lg"
                      :src="buildImageUrl(item)"
                      @error="errorImg"
                    />
                    <p>{{ item.node.name }}</p>
                  </div>
                </b-td>
                <b-td @click.stop.prevent="!seleccionar ? onRowSelected(item) : ''">
                  <span
                    v-if="item.node.state == 'GRE'"
                    class="text-success"
                  >{{ $t('ecommerce.public') }}</span>
                  <span
                    v-if="item.node.state == 'YEW'"
                    class="text-warning"
                  >{{ $t('dataGeneric.internal') }}</span>
                  <span
                    v-if="item.node.state == 'RED'"
                    class="text-danger"
                  >{{ $t('ecommerce.NoPublic') }}</span>
                  <span
                    v-if="item.node.state == 'BCK'"
                    class="text-dark"
                  >{{ $t('ecommerce.NoPublic') }}</span>
                </b-td>
                <b-td @click.stop.prevent="!seleccionar ? onRowSelected(item) : ''">
                  <p>
                    {{ fechaFormato(item.node.emisionDate) }}
                  </p>
                </b-td>
                <b-td @click.stop.prevent="!seleccionar ? onRowSelected(item) : ''">
                  <p>{{ fechaFormato(item.node.publishDate) }}</p>
                </b-td>
                <b-td @click.stop.prevent="!seleccionar ? onRowSelected(item) : ''">
                  <p>{{ fechaFormato(item.node.expirationDate) }}</p>
                </b-td>
                <b-td>
                  <b-dropdown
                    v-if="!seleccionar"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template #button-content>
                      <feather-icon
                        color="white"
                        icon="MoreVerticalIcon"
                        size="20"
                      />
                    </template>
                    <b-dropdown-item @click.stop.prevent="selectItem(item.node, 0)">
                      <feather-icon
                        icon="CornerRightUpIcon"
                        size="18"
                      />
                      {{ $t('addToCategory') }}
                    </b-dropdown-item>
                    <b-dropdown-item @click.stop.prevent="moveToCat(item.node)">
                      <feather-icon
                        icon="CornerRightUpIcon"
                        size="18"
                      />
                      {{ $t('contents.moveToCat') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="!isExternalLive(item.node)"
                      @click.stop.prevent="deleteContent(item.node.id)"
                    >
                      <feather-icon
                        icon="DeleteIcon"
                        size="18"
                      />
                      {{ $t("contents.dropContent") }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-form-checkbox
                    v-else
                    disabled
                    :value="item.node.id"
                  />
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-form-checkbox-group>
      </b-tab>
    </b-tabs>

    <div
      v-if="hasMore"
      class="d-flex justify-content-center align-self-center mt-2"
    >
      <small>{{ $t('downToSee') }}</small><feather-icon
        icon="ArrowDownIcon"
        size="20"
        class="ml-50"
      />
    </div>

    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
      @hide="cleanData"
    >
      <div class="d-block text-center">
        <category-selector
          v-if="mov == 0"
          :type="true"
          :especial="true"
          :sponsor="isSponsor"
          :columns="categorySelectorTable"
          @confirm-selection="addToCategory"
        />
        <category-selector
          v-else
          :type="true"
          :especial="true"
          :sponsor="isSponsor"
          :columns="categorySelectorTable"
          @confirm-selection="moveContents"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <div ref="infiniteScroll" />
  </div>
</template>

<script>
import configContentType from '@core/utils/contentType'
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'
import {
  utf8ToB64, removeResources, deactivateSlider, showToast, checkContents, moveToCategory, isExternalLive, addContentsToCategory,
  isEmpty, messageError, isUserInGroup, isSubgroupEmpty,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'
import * as constants from '@core/utils/constants'
import vSelect from 'vue-select'
import axios from '@axios'

import {
  BCol,
  BImgLazy,
  BRow,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  BFormInput,
  BFormCheckbox,
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BTab,
  BTabs,
  BCardTitle,
  BOverlay,
  BFormSelect,
  BFormCheckboxGroup,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
} from 'bootstrap-vue'
import CategorySelector from '../../common/CategorySelector.vue'

const noCover = require('@/assets/images/backend/nocover.jpeg')
const fileError = require('@/assets/images/backend/file-error.png')

export default {
  name: 'Search',
  components: {
    BDropdown,
    BDropdownItem,
    BTab,
    BTabs,
    BCol,
    BFormCheckbox,
    BTableSimple,
    BThead,
    vSelect,
    BImgLazy,
    BTr,
    BTh,
    BTd,
    BTbody,
    BModal,
    BRow,
    BFormCheckboxGroup,
    BFormInput,
    BCard,
    BCardHeader,
    BCardBody,
    BFormSelect,
    BCardTitle,
    CategorySelector,
    BCardText,
    BButton,
    BOverlay,
  },
  data() {
    return {
      orderBy: true,
      isEmpty,
      state: null,
      optionsOrder: [
        { value: true, text: this.$t('orderByRecents') },
        { value: false, text: this.$t('orderByOld') },
      ],
      tableColumns: [
        { key: 'name', label: this.$t('firstName') },
        { key: 'state', label: this.$t('code.status') },
        { key: 'emision', label: this.$t('contents.emision') },
        { key: 'date_publication', label: this.$t('contents.datePublication') },
        { key: 'date_final', label: this.$t('contents.dateFinishing') },
        { key: 'actions', label: this.$t('message.tableHeader.action') },
      ],
      searchInput: '',
      search: [],
      active: false,
      show: false,
      searching: false,
      todos: false,
      single: 'single',
      isSponsor: false,
      userData: getUserData(),
      selected: null,
      categoryId: null,
      catContentId: [],
      typeOptions: configContentType(true, false),
      options: [
        { value: null, text: this.$t('code.status') },
        { value: 'RED', text: `🔴 ${this.$t('ecommerce.NoPublic')}` },
        { value: 'YEW', text: `🟡 ${this.$t('dataGeneric.internal')}` },
        { value: 'GRE', text: `🟢 ${this.$t('ecommerce.public')}` },
      ],
      catSelc: null,
      optionsStatus: [
        { value: null, text: this.$t('code.status') },
        { value: 'RED', text: `🔴 ${this.$t('ecommerce.NoPublic')}` },
        { value: 'YEW', text: `🟡 ${this.$t('dataGeneric.internal')}` },
        { value: 'GRE', text: `🟢 ${this.$t('ecommerce.public')}` },
      ],
      seleccionar: false,
      orphan: false,
      selectContent: [],
      headers: {},
      infoContent: [],
      catTableData: null,
      categorySelectorTable: [
        { key: 'image', label: this.$t('zones.category') },
        { key: 'name', label: this.$t('administrators.name') },
        { key: 'type', label: this.$t('type') },
      ],
      isPod: false,
      mov: 0,
      changed: false,
      page: 1,
      isLoading: false,
      hasMore: true,
      categoriesSearch: [],
      searchName: '',
      typingTimer: null,
      doneTypingInterval: 500,
    }
  },
  watch: {
    catSelc() {
      this.onKeyUp(true)
    },
    selected() {
      this.onKeyUp(true)
    },
    orphan() {
      this.onKeyUp(true)
    },
    seleccionar() {
      this.selectContent = []
      this.infoContent = []
      this.todos = false
    },
    todos() {
      if (this.todos) {
        this.search.forEach(element => {
          this.selectContent.push(element.node.id)
          this.infoContent.push(element.node)
        })
      } else {
        this.selectContent = []
        this.infoContent = []
      }
    },
    orderBy() {
      this.onKeyUp(true)
    },
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
    } catch (error) {
      console.log(error)
    }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }

    window.addEventListener('scroll', this.handleScroll)
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = isUserInGroup(this.userData, constants.sponsor)
    }
    this.onKeyUp(true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  activated() {
    this.$route.meta.keepAlive = true
  },
  methods: {
    isExternalLive,
    searchCategorySelect() {
      this.searchName += ''
      if (this.searchName.trim().length >= 3) {
        this.allCategory()
      }
    },
    handleScroll() {
      const element = this.$refs.infiniteScroll
      const rect = element.getBoundingClientRect()
      if (rect.top <= window.innerHeight && this.hasMore && !this.isLoading) {
        this.getContents()
      }
    },
    deactivatedMenu() {
      const query = `{
        allMenu (category:"${utf8ToB64(`id:${this.catSelc.value}`)}"){
          edges {
            node {
              id
              isActive
              category {
                id
              }
            }
          }
        }
      }`
      axios
        .post('', {
          query,
        }).then(result => {
          messageError(result, this)

          const res = result.data.data.allMenu.edges
          let notMutation = 0
          if (res.length !== 0) {
            let queryMut = 'mutation{'
            res.forEach((element, i) => {
              if (element.node.isActive) {
                queryMut += `menu_${i + 1}: updateMenu(id:"${element.node.id}",input:{isActive:false}){
                  menu{
                    id
                    isActive
                  }
                }`
              } else {
                notMutation += 1
              }
            })
            queryMut += '}'
            if (notMutation < res.length) {
              axios
                .post('', {
                  query: queryMut,
                }).then(response => {
                  messageError(response, this)

                  showToast(this.$t('deactivateCat'), 1, this)
                }).catch(err => {
                  console.log(err)
                })
            }
          }
        }).catch(err => {
          console.log(err)
        })
    },
    cleanData() {
      if (!this.seleccionar && !this.changed) {
        this.selectContent = []
        this.infoContent = []
      }
    },
    getContents() {
      const { headers } = this
      this.show = true
      this.isLoading = true
      axios
        .post('', {
          query: `
         query{
        allContents(
          search:"${this.searchInput}",
          orphan:${this.orphan},
          ${this.isSponsor && isSubgroupEmpty(this.userData) ? `creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}
          orderBy:${this.orderBy === true ? '"-createdAt"' : '"createdAt"'},
          first:12,
          offset:${12 * (this.page - 1)},
          client:"${this.userData.profile.client.id}"
          ${!isEmpty(this.catSelc) ? `,categories:"${utf8ToB64(`id:${this.catSelc.value}`)}"` : ''}      
          ${!isEmpty(this.selected) ? `,state : "${this.selected}"` : ''}
          ){
            pageInfo {
            hasNextPage
            
          }
          totalCount
          edges {
            node {
              id
              name
              imageUrl
              type
              emisionDate
              publishDate
              expirationDate
              isActive
              state
              contentSlide {
                totalCount                                
              }
              contentContentOrder
              ${!isEmpty(this.catSelc)
    ? `(category:"${utf8ToB64(
      `category:${this.catSelc.value}`,
    )}")`
    : ''
}{
                  totalCount
                  edges {
                    node {
                      id
                      order
                      category {
                        id
                        name
                      }
                    }
                  }
                }
              categories ${!isEmpty(this.catSelc) ? `(id : "${this.catSelc.value}")` : ''
}{
                totalCount
                edges {
                  node {
                    id
                    name
                    isFinal
                  }
                }
              }
              contentResources(isInitial:true){
                edges{
                  node{
                    resource{
                      typeStream
                    }
                  }
                }
              }

            }
          }
        }
      }
        `,
        }, { headers })
        .then(response => {
          messageError(response, this)

          const srch = response.data.data.allContents.edges
          if (response.data.data.allContents.pageInfo.hasNextPage) {
            this.search = [...this.search, ...srch]
            this.page += 1
          } else {
            this.search = [...this.search, ...srch]
            this.hasMore = false
          }
          this.isLoading = false
          this.show = false
        }).catch(res => {
          console.log(res)
        })
    },

    buildImageUrl(folder) {
      return folder.node.imageUrl == null || folder.node.imageUrl.length === 0
        ? noCover
        : folder.node.imageUrl
    },
    showModal(type) {
      this.mov = type
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
      this.show = false
    },
    addToCategory(data) {
      this.changed = true
      this.hideModal()
      addContentsToCategory(data.node.id, this.selectContent).then(result => {
        messageError(result, this)
        showToast(`${this.$t('contentsAddToCat')} ${data.node.name}`, 1, this)
        this.selectContent = []
        this.infoContent = []
      }).catch(() => {
        showToast(this.$t('error'), 2, this)
      })
    },
    delayMethod() {
      /* IMPORTANT! Do not change the event, you must access target.value to get the input value, and not from keyup.  */
      // eslint-disable-next-line no-restricted-globals
      this.searchName = event.target.value

      clearTimeout(this.typingTimer)
      this.typingTimer = setTimeout(() => {
        this.searchCategorySelect() // Envuelve la llamada dentro de una función de flecha
      }, this.doneTypingInterval)
    },
    allCategory() {
      const name = this.searchName
      if (name.trim().length > 0) {
        this.searching = true
        axios
          .post('', {
            query: `
              query{
            categoriesUnlimited(name:"${name}",client: "${this.userData.profile.client.id}"
            ${this.isSponsor ? `,filter_Sponsor:"${this.userData.id}"` : ''}    
            ) {
                edges{
                  node{
                  id
                  name                  
                }
              }
            }
          }
            `,
          })
          .then(res => {
            messageError(res, this)

            this.catTableData = res.data.data.categoriesUnlimited.edges
            this.categoriesSearch = []
            this.catTableData.forEach(element => {
              this.categoriesSearch.push({ label: element.node.name, value: element.node.id })
            })
            this.searching = false
          }).catch(err => {
            console.log(err)
          })
      }
    },
    selectItem(node, mov) {
      if (node) {
        if (node.type === 'POD' || node.type === 'AUC') {
          this.isPod = true
        } else {
          this.isPod = false
        }
        this.selectContent.push(node.id)
        this.infoContent.push(node)
      }
      this.showModal(mov)
    },
    routeToContentView(folderId) {
      this.$router.push({ name: 'contents-edit', params: { id: folderId } })
    },

    status() {
      this.show = true
      let isActive = false
      let deactivate = false
      let active = false
      let arrayCont = ''
      let estado = ''

      switch (this.state) {
        case 'GRE':
          isActive = true
          active = true
          estado = this.$t('ecommerce.public')
          break

        case 'YEW':
          isActive = true
          active = false
          estado = this.$t('dataGeneric.internal')
          break

        case 'RED':
          isActive = false
          active = false
          estado = this.$t('ecommerce.NoPublic')
          break
        default:
          break
      }
      this.infoContent.forEach(element => {
        if (element.contentSlide.totalCount !== 0 && !active) {
          if (arrayCont) {
            arrayCont += ', '
          }
          arrayCont += element.name
          deactivate = true
        }
      })
      if (deactivate) {
        this.$swal({
          title: this.$t('dataGeneric.atention'),
          text: this.$t('descCont', { arrayCont }),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('next'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            let query = 'mutation{'
            this.selectContent.forEach((element, i) => {
              query += `
            m${i + 1}:updateContents(id:"${element}",input:{state:${this.state
},isActive:${isActive}}){
              content {
                id
                isActive
              }
            }`
            })
            query += '}'

            axios
              .post('', {
                query,
              })
              .then(res => {
                messageError(res, this)
                deactivateSlider(this.selectContent, false, 'CONT').then(() => {

                }).catch(err => {
                  console.log(err)
                })

                if (this.selectContent.length >= 1) {
                  showToast(
                    this.selectContent.length === 1
                      ? this.$t('statusContentCh') + estado
                      : this.$t('statusContentsCh') + estado, 1, this,
                  )
                }
                this.show = false
                this.onKeyUp(true)
              })
          } else {
            this.show = false
          }
        }).catch(() => {
          this.show = false
        })
      } else {
        let query = 'mutation{'
        this.selectContent.forEach((element, i) => {
          query += `
          m${i + 1}: updateContents(id: "${element}", input: { state: ${this.state
}, isActive: ${isActive}}) {
            content {
                  id
                  isActive
                }
              }
              `
        })
        query += '}'
        axios
          .post('', {
            query,
          })
          .then(res => {
            messageError(res, this)
            if (this.selectContent.length >= 1) {
              showToast(
                this.selectContent.length === 1
                  ? this.$t('statusContentCh') + estado
                  : this.$t('statusContentsCh') + estado, 1, this,
              )
            }
            this.show = false
            this.onKeyUp(true)
          })
      }
    },
    async checkAnyExternalLive(infoContent) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of infoContent) {
        try {
          const typeStream = item.contentResources.edges[0].node.resource?.typeStream
          if (typeStream === 'AWS' || typeStream === 'WOW') {
            // Muestra un mensaje de error si es "AWS" o "WOW"
            return new Promise(resolve => {
              this.$swal({
                title: this.$t('incompatibles'),
                text: this.$t('externalServerError')
                  + this.$t('liveSection'),
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then(() => {
                resolve(true)
              })
            })
          }
        } catch (error) {
          // Manejar cualquier error que ocurra al acceder a las propiedades
          console.error('Error al verificar el tipo de stream:', error)
        }
      }
      return false
    },

    async deleteContent(id = null) {
      if (id != null) {
        this.selectContent = []
        this.infoContent = []
        this.selectContent.push(id)
      }

      const checkAnyExternalLive = await this.checkAnyExternalLive(this.infoContent)
      if (checkAnyExternalLive === false) {
        this.$swal({
          title: this.$t('code.title'),
          text: this.$t('deleteContent'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('code.confirm'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.show = true
            let query = '{'
            this.selectContent.forEach((element, i) => {
              query += `cont_${i
                + 1}: allContents(id:"${element}") {
                  edges {
                    node {
                      id
                      name
                      contentResources {
                        totalCount
                        edges {
                          node {
                            id              
                            resource {
                              id
                              name
                              file {
                                id
                                name
                              }
                              resourceContent {
                                totalCount
                                edges {
                                  node {
                                    id
                                    name
                                  }
                                }
                              }
                            }
                          }
                        }
                      }      
                    }
                  }
                }`
            })
            query += '}'
            axios
              .post('', {
                query,
              }).then(res => {
                messageError(res, this)

                const contentResources = res.data.data
                removeResources(contentResources).then(resultado => {
                  if (resultado) {
                    this.$swal({
                      title: this.$t('code.title'),
                      text: this.$t('code.resourceOrphan', { resourceName: resultado.name }),
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: this.$t('code.confirm'),
                      cancelButtonText: this.$t('dataGeneric.cancel'),
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                      },
                      buttonsStyling: false,
                    }).then(response => {
                      if (response.value) {
                        let queryRes = 'mutation{'
                        resultado.id.forEach((element, i) => {
                          queryRes += `
                                  resource_${i + 1}: deleteResource(id:"${element}") {
                                      found
                                      deletedId
                                    }`
                        })
                        if (resultado.file.length) {
                          resultado.file.forEach((element, i) => {
                            queryRes += `
                                  file_${i + 1}: deleteFile(id:"${element}") {
                                      found
                                      deletedId
                                    }`
                          })
                        }
                        queryRes += '}'
                        axios.post('', { query: queryRes }).then(r => {
                          messageError(r, this)

                          this.delete()
                        }).catch(err => {
                          console.log(err)
                        })
                      } else {
                        this.delete()
                      }
                    }).catch(err => {
                      console.log(err)
                    })
                  } else {
                    this.delete()
                  }
                }).catch(err => {
                  console.log(err)
                })
              }).catch(err => {
                console.log(err)
              })
          }
        })
      }
    },
    delete() {
      checkContents(this.selectContent).then(() => {
        let query = 'mutation{'
        this.selectContent.forEach((element, i) => {
          query += `a_${i
            + 1}: updateContents(id:"${element}",input:{resources:[]})
                        {
                          content {
                            id
                            resources {
                              edges {
                                node {
                                  id
                                }
                              }
                            }
                          }
                        }
                b_${i + 1}: deleteContents(id:"${element}") {
                    found
                    deletedId
                  }`
        })
        query += '}'
        axios
          .post('', {
            query,
          })
          .then(r => {
            messageError(r, this)

            this.selectContent.forEach(element => {
              this.search = this.search.filter(item => item.node.id !== element)
            })
            this.selectContent = []
            this.infoContent = []

            showToast(this.$t('deleteSuccess'), 1, this)

            this.show = false
          })
          .catch(() => {
            showToast(this.$t('deleteError'), 2, this)

            this.show = false
          })
      }).catch(err => {
        console.log(err)
      })
    },

    back() {
      window.history.back()
    },

    errorImg(e) {
      e.target.src = fileError
      // "https://cdn.iconscout.com/icon/free/png-256/file-error-1470245-1244839.png";
    },
    clickCheck(node) {
      const i = this.selectContent.indexOf(node.id)
      const a = this.infoContent.map(e => e.id).indexOf(node.id)

      if (i !== -1) {
        this.selectContent.splice(i, 1)
        this.infoContent.splice(a, 1)
      } else {
        this.selectContent.push(node.id)
        this.infoContent.push(node)
      }
    },
    onKeyUp(pass = false) {
      let keycode = 0
      try {
        // eslint-disable-next-line no-restricted-globals
        keycode = event.keyCode || event.which
      // eslint-disable-next-line no-empty
      } catch (error) {

      }

      if ((keycode === 13 || pass) && !this.show) {
        this.search = []
        this.hasMore = true
        this.page = 1
        this.getContents()
      }
    },
    moveContents(category) {
      this.changed = true

      moveToCategory(this.mov === 2 ? null : this.catSelc.value, category.node.id, this.selectContent, this.mov).then(() => {
        this.onKeyUp(true)
        this.selectContent = []
        this.infoContent = []
        showToast(this.$t('success'), 1, this)
        this.hideModal()
      }).catch(() => {
        showToast(this.$t('error'), 2, this)
        this.hideModal()
      })
    },
    onRowSelected(items) {
      this.$router.push({
        name: 'contents-edit',
        params: { id: items.node.id },
      })
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },
    deleteFromCategory() {
      this.$swal({
        title: this.$t('removeFromCat'),
        text: this.$t('sureOrphan'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yesRemove'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let query = ' query{'

            for (let i = 0; i < this.selectContent.length; i += 1) {
              query += `
        q${i + 1}: allContents(id: "${this.selectContent[i]}"){
            edges {
              node {
                id
                contentContentOrder
                            ${!isEmpty(this.catSelc)
    ? `(category:"${utf8ToB64(
      `category:${this.catSelc.value}`,
    )}")`
    : ''
} {
                totalCount
                  edges {
                    node {
                        id
                        order
                    category {
                          id
                          name
                        }
                      }
                    }
                  }
                          categories {
                            edges {
                              node {
                        id
                      }
                    }
                  }
                }
              }
            }
            `
            }
            query += '}'
            axios
              .post('', { query })
              .then(res => {
                messageError(res, this)

                let data = []
                let queryUp = ' mutation{'

                for (let a = 0; a < this.selectContent.length; a += 1) {
                  data = res.data.data[`q${a + 1}`].edges[0].node

                  const categoriasRes = []

                  try {
                    const categories = data.categories.edges.filter(
                      element => element.node.id !== this.catSelc.value,
                    )
                    categories.forEach(item => res.push(item.node.id))
                  // eslint-disable-next-line no-empty
                  } catch (error) { }

                  queryUp += `
                    m${a + 1}: updateContents(id: "${data.id}", input: {
                        categories: [${categoriasRes}]
                      }) {
                        content{
                          id
                          name
                          client {
                            id
                            name
                          }
                          categories {
                            edges {
                              node {
                                id
                                name
                              }
                            }
                          }
                        }
                      }

                      `
                }
                queryUp += '} '

                axios
                  .post('', { query: queryUp })
                  .then(resp => {
                    messageError(resp, this)

                    this.onKeyUp(true)
                  })
                  .catch(err => {
                    console.log(err)
                  })
                let notMutation = 0
                query = ' mutation{ '
                this.selectContent.forEach((element, a) => {
                  data = res.data.data[`q${a + 1}`].edges[0].node
                  if (data.contentContentOrder.totalCount !== 0) {
                    query += `
                  m${a + 1}: deleteContentOrder(id: "${data.contentContentOrder.edges[0].node.id
} ") {
                        found
                        deletedId
                                }
                        `
                  } else {
                    notMutation += 1
                  }
                })
                query += '}'

                if (notMutation < this.selectContent.length) {
                  axios
                    .post('', { query })
                    .then(resp => {
                      messageError(resp, this)
                    })
                    .catch(err => {
                      console.log(err)
                    })
                }
              })
              .catch(res => {
                console.log(res)
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    moveToCat(node = null) {
      this.show = true
      this.$swal({
        title: this.$t('code.title'),
        text:
          this.$t('contentsMoves'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('next'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.selectItem(node, 2)
        } else {
          this.show = false
        }
      })
    },

  },
}
</script>

<style scoped>
#search .card-img-top {
  /*width: 100%;
  height: 25vh;
  object-fit: cover;*/
}

#search .actionsBar {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#search .folder img {
  height: 11vw;
  object-fit: cover;

}

@media screen and (max-width: 767px) {
  #search .folder img {
    height: 40vw;
    object-fit: cover;
  }
}

@media screen and (min-width: 767px) {

  #search .folder img {
    height: 23vw;
    object-fit: cover;

  }
}

@media screen and (min-width: 1200px) {

  #search .folder img {
    height: 12vw;
    object-fit: cover;

  }
}

#search .card-body {
  padding: 1rem;
}

#search .card-body h4 {
  font-size: 1rem !important;
}

#search .card-header {
  padding: 1rem;
}

#search .card-header h4 {
  font-size: 1rem !important;
}

#search .card-body .actionsBar {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;

}

#search .right {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

#search .left {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

#search .list-group-item {
  transition: all 1s;
}

#search #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#search #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

#search .vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__clear,
.vs--disabled .vs__search,
.vs--disabled .vs__selected,
.vs--disabled .vs__open-indicator {
  cursor: not-allowed;
  background-color: transparent !important;
  opacity: 0.8 !important;
  /* background-color: #f8f8f8; */
}
</style>
